exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-config-tsx": () => import("./../../../src/pages/config.tsx" /* webpackChunkName: "component---src-pages-config-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-index-tsx": () => import("./../../../src/pages/posts/index.tsx" /* webpackChunkName: "component---src-pages-posts-index-tsx" */),
  "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-2023-11-about-mdx": () => import("./../../../src/pages/posts/{mdx.frontmatter__slug}.tsx?__contentFilePath=/github/workspace/posts/2023/11/about.mdx" /* webpackChunkName: "component---src-pages-posts-mdx-frontmatter-slug-tsx-content-file-path-posts-2023-11-about-mdx" */),
  "component---src-pages-scratch-index-tsx": () => import("./../../../src/pages/scratch/index.tsx" /* webpackChunkName: "component---src-pages-scratch-index-tsx" */)
}

