import { createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../context"
import { UiFunction } from "@tallman5/core-js"

export type Message = {
    uiFunction: UiFunction
    details?: string
    displayTimeout: number
    id: number
    title?: string
}

export type Task = {
    id: number
    title: string
}

export type AppState = {
    messageList: Message[]
    taskList: Task[]
}

const initialState: AppState = {
    messageList: [],
    taskList: [],
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        // Messages
        addMessage: (state: AppState, action) => {
            let newId = Math.max(...state.messageList.map(o => o.id), 0);
            newId++;
            const newMsg: Message = {
                ...action.payload,
                id: newId,
            }
            state.messageList.push(newMsg);
        },
        clearMessages: (state) => {
            state.messageList = [];
        },
        deleteMessage: (state: AppState, action) => {
            state.messageList = state.messageList.filter(message => action.payload !== message.id)
        },

        // Tasks
        addTask: (state: AppState, action) => {
            let newId = Math.max(...state.taskList.map(o => o.id), 0)
            newId++
            action.payload.id = newId
            state.taskList.push(action.payload)
        },
        clearTasks: (state) => {
            state.taskList = [];
        },
        deleteTask: (state: AppState, action) => {
            state.taskList = state.taskList.filter(o => action.payload !== o.id)
        },
        deleteTaskByTitle: (state: AppState, action) => {
            state.taskList = state.taskList.filter(o => action.payload !== o.title)
        },
    },
});

// Base selectors
export const selectMessagesBase = (state: RootState) => state.app.messageList;
export const selectTasksBase = (state: RootState) => state.app.taskList;

// Reselectors
export const selectMessages = createSelector(selectMessagesBase, (items) => items)
export const selectTasks = createSelector(selectTasksBase, (items) => items)

// Methods

// Main Exports
export const { addMessage, clearMessages, deleteMessage,
    addTask, clearTasks, deleteTask, deleteTaskByTitle } = appSlice.actions;
export default appSlice.reducer;
