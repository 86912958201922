import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../context';

export type AppUserState = {
    isDarkMode: boolean
}

const initialState: AppUserState = {
    isDarkMode: true,
}

export const appUserSlice = createSlice({
    name: 'appUser',
    initialState,
    reducers: {
        setIsDarkMode: (state: AppUserState, action) => {
            console.log(`setIsDarkMode: ${action.payload}`);
            state.isDarkMode = action.payload;
        },
    },
});

// Base Selectors
export const selectAppUserBase = (state: RootState) => state.appUser;
export const selectIsDarkMode = (state: RootState) => state.appUser.isDarkMode;

// Reselectors
export const selectAppUser = createSelector(selectAppUserBase, item => item);

// Methods

// Main slice exports
export const { setIsDarkMode } = appUserSlice.actions;
export default appUserSlice.reducer;
