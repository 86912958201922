import { createSelector, createSlice } from "@reduxjs/toolkit"
import { AppThunk, RootState } from "../../context"
import { IConnection, configFromKlipper } from "../../models"
import { Message, addMessage, addTask, deleteTaskByTitle } from "../app/appSlice"
import { UiFunction } from '@tallman5/core-js';

export type ConnectionsState = {
    activeConnection?: IConnection,
}

const initialState: ConnectionsState = {
}

export const connectionSlice = createSlice({
    name: 'connections',
    initialState,
    reducers: {
        setActiveConnection: (state: ConnectionsState, action) => {
            state.activeConnection = action.payload;
        },
    },
});

// Base selectors
export const selectActiveConnectionBase = (state: RootState) => state.connections.activeConnection;
export const selectActiveHostBase = (state: RootState) => state.connections.activeConnection?.host;

// Reselectors
export const selectActiveConnection = createSelector(selectActiveConnectionBase, (item) => item);
export const selectActiveHost = createSelector(selectActiveHostBase, (item) => item);

// Methods
export const connect = (host: string): AppThunk => async (dispatch, getState) => {
    const taskTitle = `Connecting to ${host}...`
    dispatch(addTask({ id: 0, title: taskTitle }))

    const connectMessage: Message = {
        displayTimeout: 3000,
        id: 0,
        title: 'Success connecting',
        uiFunction: UiFunction.Success,
    }

    const newConnection: IConnection = {
        host,
    };
    const url = `http://${host}/printer/objects/query?configfile`;

    const cfg = await fetch(url)
        .then((response) => {
            if (!response.ok) {
                const msg = `Request failed: ${response.status} - ${response.statusText}`;
                throw new Error(msg);
            }
            return response.json();
        }).then((data) => {
            const newCfg = data?.result?.status?.configfile?.config;
            return newCfg;
        }).catch(error => {
            connectMessage.title = "An error occurred connecting";
            connectMessage.details = "Please check the About page for connection issues."
            connectMessage.uiFunction = UiFunction.Danger;
            connectMessage.displayTimeout = 10000;
            return null
        });

    if (cfg) {
        newConnection.config = configFromKlipper(cfg);
    }

    dispatch(setActiveConnection(newConnection));
    dispatch(deleteTaskByTitle(taskTitle))
    dispatch(addMessage(connectMessage));
};

// Main Exports
export const { setActiveConnection } = connectionSlice.actions;
export default connectionSlice.reducer;
